<template>
    <el-main>
        <div class="cards">
            <el-card class="box-card">
                <div>总收入</div>
                <div class="num">{{ total_amount.toFixed(2) }}</div>
            </el-card>
            <el-card class="box-card">
                <div>本月收入</div>
                <div class="num">{{ month_total_amount.toFixed(2) }}</div>
            </el-card>
            <el-card class="box-card">
                <div>上月收入</div>
                <div class="num">{{ last_month_total_amount.toFixed(2) }}</div>
            </el-card>
        </div>

        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="来源类型">
                <el-select size="small" v-model="form.service_type" placeholder="请选择" clearable>
                    <el-option v-for="item in service_type" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="收益/支出">
                <el-select size="small" v-model="form.status" placeholder="请选择">
                    <el-option label="入账" value="1"></el-option>
                    <el-option label="出账" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="产生时间">
                <el-date-picker v-model="form.create_time" size="small" type="daterange" range-separator="~"
                    start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="订单状态">
                <el-select size="small" v-model="form.type" placeholder="请选择" clearable>
                    <!-- <el-option v-for="item in type" :key="item.value" :label="item.label" :value="item.value"></el-option> -->
                    <el-option label="已入账" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="关联单号">
                <el-input size="small" v-model="form.keyworks"></el-input>
            </el-form-item>

            <el-form-item label=" " label-width="25px">
                <el-button type="primary" @click="getList(1)" size="small">搜索</el-button>
                <el-button size="small" @click="exportOrder">导出</el-button>
                <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" row-key="id" default-expand-all :header-cell-style="{ 'background-color': '#F8F9FA' }"
            :tree-props="{ children: '_child', hasChildren: 'hasChildren' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="service_type" label="来源类型" align="center">
                <template slot-scope="scope">{{ service_type.find(v => v.value ==
                    scope.row.service_type) ? service_type.find(v => v.value == scope.row.service_type).label :
                    scope.row.remark
                }}</template>
            </el-table-column>
            <el-table-column prop="status" label="收益/支出" align="center">
                <template slot-scope="scope">{{ scope.row.status == 1 ? '入账' : '出账' }}</template>
            </el-table-column>
            <el-table-column label="关联用户注册账号" align="center">
                <template slot-scope="scope">{{ scope.row.user_info ? scope.row.user_info.mobile : '--' }}</template>
            </el-table-column>
            <el-table-column prop="money" label="金额" align="center">
                <template slot-scope="scope">
                    <div :style="{ color: scope.row.status == 1 ? '#67C23A' : '#F56C6C' }">{{ scope.row.status == 1 ? '+' :
                        '-' }}{{ scope.row.money
    }}</div>
                </template>
            </el-table-column>
            <el-table-column label="订单状态" align="center">
                <template slot-scope="scope">
                    <div v-if="scope.row.type==2">已入账</div>
                </template>
            </el-table-column>
            <el-table-column prop="order_sn" label="关联单号" align="center"></el-table-column>
            <el-table-column prop="picture" label="产生时间" align="center">
                <template slot-scope="scope">{{ getDateformat(scope.row.create_time) }}</template>
            </el-table-column>
        </el-table>
        <Paging :total="total" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
    </el-main>
</template>

<script>
import config from '@/util/config';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            form: {
                page: 1,
                rows: 10,
                service_type: '',
                status: '',
                type: 2,
                create_time: '',
                keyworks_type: 'goods_order_sn',
                keyworks: ''
            },
            service_type: [
                {
                    value: 1,
                    label: '商品订单收入'
                },
                {
                    value: 2,
                    label: '服务订单收入'
                },
                {
                    value: 3,
                    label: '保证金订单'
                },
                {
                    value: 4,
                    label: '物业打款'
                },
                {
                    value: 5,
                    label: '技工提现打款'
                },
                {
                    value: 6,
                    label: '保证金退保'
                },
                {
                    value: 7,
                    label: 'VIP费用'
                },
                {
                    value: 8,
                    label: '店铺提现打款'
                },
                {
                    value: 9,
                    label: '续费vip'
                },
                {
                    value: 10,
                    label: '到店付款'
                },
                {
                    value: 11,
                    label: '商品订单金额（采购）'
                },
                {
                    value: 12,
                    label: '活动发起金额'
                },
                // {
                //     value: 13,
                //     label: '消费卡订单'
                // }
            ],
            type: [
                {
                    value: 1,
                    label: '待入账'
                },
                {
                    value: 2,
                    label: '已入账'
                },
                {
                    value: 3,
                    label: '已失效'
                },
                {
                    value: 4,
                    label: '冻结中'
                }
            ],
            list: [],
            total: 0,
            month_total_amount: 0,
            total_amount: 0,
            last_month_total_amount: 0
        };
    },
    created () {
        this.getList();
    },
    methods: {
        getDateformat,
        updateData (val, status) {
            if (status == 0) {
                this.form.rows = val;
                this.getList();
            } else {
                this.form.page = val;
                this.getList();
            }
        },
        getList (page = 0) {
            let data = Object.assign({}, this.form)
            if (page) {
                data.page = 1
            }
            if (data.create_time) {
                data.create_time = [data.create_time[0] / 1000, data.create_time[1] / 1000,]
            } else {
                delete data.create_time
            }
            this.$axios
                .post(this.$api.repair.finance.fundDetails, data)
                .then(res => {
                    if (res.code == 0) {
                        let { total, list, month_total_amount, total_amount, last_month_total_amount } = res.result
                        this.total = total
                        this.list = list
                        this.month_total_amount = month_total_amount * 1
                        this.total_amount = total_amount * 1
                        this.last_month_total_amount = last_month_total_amount * 1
                    }
                });
        },
        exportOrder () {
            if (this.form.create_time) {
                this.form.create_time = [this.form.create_time[0] / 1000, this.form.create_time[1] / 1000,]
            } else {
                delete this.form.create_time
            }
            let obj = {
                create_time: this.form.create_time,
                service_type: this.form.service_type,
                status: this.form.status,
                type: this.form.type,
                keyworks_type: 'goods_order_sn',
                keyworks: this.form.keyworks,
            }
            this.$axios
                .post(this.$api.repair.finance.fundDetailsExport, obj)
                .then(res => {
                    if (res.code == 0) {
                        let path = config.baseurl + '/' + res.result;
                        let a = document.createElement('a');
                        a.href = path;
                        a.id = 'download';
                        document.body.appendChild(a);
                        a.click();
                        let aDom = document.getElementById('download');
                        document.body.removeChild(aDom);
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .cards {
        display: flex;
        margin-bottom: 20px;

        .box-card {
            width: 300px;
            font-weight: bold;

            .num {
                font-size: 40px;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .box-card+.box-card {
            margin-left: 50px;
        }
    }

    .el-table {
        flex: 1;

        /deep/ .el-input__inner {
            border-color: #fff;
        }

        /deep/ .el-input__inner:focus {
            border-color: #409eff;
        }

        border-top: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
    }

    .edit {
        display: flex;
        align-items: center;

        span {
            margin-right: 5px;
        }

        i {
            font-size: 17px;
        }
    }

    .move .item {
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin-bottom: 20px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    // 这是点击后的旋转角度
    transform: rotate(0deg);
}

/deep/.el-table__expand-icon {
    .el-icon-arrow-right:before {
        //这是收起图标
        content: '\e723';
        font-size: 16px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    .el-icon-arrow-right:before {
        // 这是展开图标
        content: '\e722';
        font-size: 16px;
    }
}
</style>
